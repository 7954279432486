import { FunctionComponent } from 'react';
import dynamic from 'next/dynamic';

import StyledComponent from './styles';
import { Props } from './types';

const TabNavigation = dynamic(() => import('components/layout/TabNavigation'));
const PageHeader = dynamic(() => import('components/modules/panel/layout/PageHeader'));

const LayoutPageContainer: FunctionComponent<Props> = ({
    children,
    tabs,
    header,
    activeTabKey,
    onTabChange,
    onClick,
}) => {
    return (
        <StyledComponent className="layout-page-container">
            {header && (
                <PageHeader
                    {...header}
                    onClick={onClick}
                />
            )}
            {tabs ? tabs.length > 0 && (
                <TabNavigation
                    tabs={tabs}
                    activeTabKey={activeTabKey}
                    onTabChange={onTabChange}
                />
            ) : children}
        </StyledComponent>
    );
};

export default LayoutPageContainer;
