import ApiCmsSection from 'types/api/CmsSection';
import ApiFileUpload from 'types/api/FileUpload';

import FileUpload from 'models/FileUpload';
import Model from 'models/Model';

export enum SectionTypes {
    Custom = 'App\\CmsSections\\CustomCmsSection',
    Schema = 'App\\CmsSections\\SchemaCmsSection',
    TextSimpleCmsSection = 'App\\CmsSections\\TextSimpleCmsSection',
    TwoColsSimpleCmsSection = 'App\\CmsSections\\TwoColsSimpleCmsSection',
    TwoColsSimpleInvertedCmsSection = 'App\\CmsSections\\TwoColsSimpleInvertedCmsSection',
    CustomSchema = 'App\\CmsSections\\CustomSchemaCmsSection',
}

export interface SectionConfig {
    class: SectionTypes
    fields: { [key: string]: { dataType: string } }
}
export interface SectionData {
    [key: string]: string | FileUpload | null
}
export interface SectionSettings {
    filters?: Record<string, any>;
}

export default class CmsSection implements Model{
    public static MODEL_OFFER = 'App\\Models\\Product';
    public static MODEL_PRODUCT = 'App\\Models\\Product';

    id: string;
    name: string;
    slug: string;
    order: number;
    sectionConfig: SectionConfig;
    sectionData: SectionData;
    sectionSettings?:SectionSettings;
    type?: string;
    variantType?: string;

    constructor(data: ApiCmsSection) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.order = data.order;
        this.sectionConfig = data.sectionConfig;
        this.sectionData = this.parseSectionData(data);
        this.sectionSettings = data.sectionSettings;
        this.type = data.type;
        this.variantType = data.variantType;
    }

    parseSectionData = (data: ApiCmsSection): SectionData => {
        let sectionData = { ...data.sectionData };

        if (data.sectionData?.image) {
            sectionData = {
                ...sectionData,
                image: new FileUpload(data.sectionData.image as ApiFileUpload),
            };
        }

        return sectionData as SectionData;
    };
}
