import dynamic from 'next/dynamic';

export const DYNAMIC_COMPONENTS = {
    hero: {
        default: dynamic(() => import('components/modules/public/cms/sections/Hero/Default'), { ssr: true }),
        search: dynamic(() => import('components/modules/public/cms/sections/Hero/Search'), { ssr: true }),
        withForm: dynamic(() => import('components/modules/public/cms/sections/Hero/WithForm'), { ssr: true }),
    },
    benefits: {
        default: dynamic(() => import('components/modules/public/cms/sections/Benefits/Default'), { ssr: true }),
        groupedByBodyParts: dynamic(() => import('components/modules/public/cms/sections/Benefits/GroupedByBodyParts'), { ssr: true }),
    },
    packages: {
        default: dynamic(() => import('components/modules/public/cms/sections/Packages/Default'), { ssr: true }),
        withTabs: dynamic(() => import('components/modules/public/cms/sections/Packages/WithTabs'), { ssr: true }),
        withBodyPartsTabs: dynamic(() => import('components/modules/public/cms/sections/Packages/WithBodyPartsTabsNew'), { ssr: true }),
    },
    contact: {
        default: dynamic(() => import('components/modules/public/cms/sections/Contact/Default'), { ssr: true }),
        withContainer: dynamic(() => import('components/modules/public/cms/sections/Contact/WithContainer'), { ssr: true }),
        business: dynamic(() => import('components/modules/public/cms/sections/Contact/Business'), { ssr: true }),
    },
    contactDetails: {
        default: dynamic(() => import('components/modules/public/cms/sections/ContactDetails/Default'), { ssr: true }),
    },
    opinions: {
        default: dynamic(() => import('components/modules/public/cms/sections/Opinions/Default'), { ssr: true }),
    },
    blogs: {
        default: dynamic(() => import('components/modules/public/cms/sections/Blogs/Default'), { ssr: true }),
    },
    relatedArticles: {
        default: dynamic(() => import('components/modules/public/cms/sections/Blogs/Default'), { ssr: true }),
    },
    map: {
        default: dynamic(() => import('components/modules/public/cms/sections/Map/Default'), { ssr: true }),
    },
    checkout: {
        default: dynamic(() => import('components/modules/public/cms/sections/Checkout/Default'), { ssr: true }),
        withExpansion: dynamic(() => import('components/modules/public/cms/sections/Checkout/WithExpansion'), { ssr: true }),
        withQuotes: dynamic(() => import('components/modules/public/cms/sections/Checkout/WithQuotes'), { ssr: true }),
    },
    silhouette: {
        man: dynamic(() => import('components/modules/public/cms/sections/Silhouette/Man'), { ssr: true }),
        woman: dynamic(() => import('components/modules/public/cms/sections/Silhouette/Woman'), { ssr: true }),
    },
    news: {
        default: dynamic(() => import('components/modules/public/cms/sections/News/Default'), { ssr: true }),
    },
    learnMore: {
        default: dynamic(() => import('components/modules/public/cms/sections/LearnMore/Default'), { ssr: true }),
        UnorderedList: dynamic(() => import('components/modules/public/cms/sections/LearnMore/UnorderedList'), { ssr: true }),
    },
    locationsGroupedByVoivodeship: {
        default: dynamic(() => import('components/modules/public/cms/sections/LocationsGroupedByVoivodeship/Default'), { ssr: true }),
    },
    locations: {
        default: dynamic(() => import('components/modules/public/cms/sections/Locations/Default'), { ssr: true }),
    },
    faq: {
        default: dynamic(() => import('components/modules/public/cms/sections/FAQ/Default'), { ssr: true }),
    },
    headlineAndImage: {
        default: dynamic(() => import('components/modules/public/cms/sections/HeadlineAndImage/Default'), { ssr: true }),
    },
    numbers: {
        default: dynamic(() => import('components/modules/public/cms/sections/Numbers/Default'), { ssr: true }),
    },
    clinic: {
        default: dynamic(() => import('components/modules/public/cms/sections/Clinic/Default'), { ssr: true }),
    },
    twoCols: {
        default: dynamic(() => import('components/modules/public/cms/sections/TwoCols/Default'), { ssr: true }),
    },
    login: {
        default: dynamic(() => import('components/modules/public/cms/sections/Login/Default'), { ssr: true }),
    },
    resultBeforeAfter: {
        default: dynamic(() => import('components/modules/public/cms/sections/ResultBeforeAfter/Default'), { ssr: true }),
    },
    productStages: {
        default: dynamic(() => import('components/modules/public/cms/sections/ProductStages/Default'), { ssr: true }),
    },
    productInfo: {
        default: dynamic(() => import('components/modules/public/cms/sections/ProductInfo/Default'), { ssr: true }),
    },
    equipment: {
        default: dynamic(() => import('components/modules/public/cms/sections/Equipment/Default'), { ssr: true }),
    },
    seoDescription: {
        default: dynamic(() => import('components/modules/public/cms/sections/SeoDescription/Default'), { ssr: true }),
        fullContent: dynamic(() => import('components/modules/public/cms/sections/SeoDescription/FullContent'), { ssr: true }),
    },
    listSimple: {
        default: dynamic(() => import('components/modules/public/cms/sections/ListSimple/Default'), { ssr: true }),
    },
    trialVisit: {
        default: dynamic(() => import('components/modules/public/cms/sections/TrialVisit/Default'), { ssr: true }),
    },
    video: {
        default: dynamic(() => import('components/modules/public/cms/sections/Video/Default'), { ssr: true }),
    },
    bundles: {
        default: dynamic(() => import('components/modules/public/cms/sections/Bundles/Default'), { ssr: true }),
        selected: dynamic(() => import('components/modules/public/cms/sections/Bundles/Selected'), { ssr: true }),
    },
    productSelection: {
        default: dynamic(() => import('components/modules/public/cms/sections/ProductSelection/Default'), { ssr: true }),
    },
};
