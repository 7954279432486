import { FunctionComponent } from 'react';

import { SectionTypes } from 'models/CmsSection';

import { DYNAMIC_COMPONENTS } from 'consts/dynamicComponents';

import StyledComponent from './styles';
import { Props } from './types';

const PublicCmsSectionWrapper: FunctionComponent<Props> = ({ cmsSection, children }) => {
    const sectionData = cmsSection?.sectionData;
    const sectionType = cmsSection?.sectionConfig?.class;
    let locale = null;
    let override = null;

    if (sectionType === SectionTypes.Schema) {
        if (!DYNAMIC_COMPONENTS[cmsSection.type] || !DYNAMIC_COMPONENTS[cmsSection.type][cmsSection.variantType]) {
            return null;
        }

        const DynamicComponent = DYNAMIC_COMPONENTS[cmsSection.type][cmsSection.variantType];
        locale = sectionData;

        override = (overrideProps?: any) => {
            return (
                <DynamicComponent
                    translations={locale}
                    sectionSettings={cmsSection?.sectionSettings || {}}
                    {...overrideProps}
                />
            );
        };
    }

    if (sectionType === SectionTypes.Custom) {
        const content = sectionData?.content as string;

        try {
            locale = content
                ? JSON.parse(content)
                : {};
        } catch (error) {
            //Ignore json format error
            locale = {};
        }
    }

    if (sectionType === SectionTypes.CustomSchema) {
        locale = sectionData;
    }

    if (!override) {
        override = () => null;
    }

    return (
        <StyledComponent className="public-cms-section-wrapper">
            {children(locale, override)}
        </StyledComponent>
    );
};

export default PublicCmsSectionWrapper;
